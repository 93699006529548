import { AuthTokenPayload } from 'actions/lib/jwtActions';
import { jwtData } from 'config/config';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { io, Socket } from 'socket.io-client';

const useAuthenticationSocket = (): void => {
  useEffect(() => {
    const token = localStorage.getItem(jwtData.authToken);
    if (!token) return;

    const decodedToken = jwtDecode<AuthTokenPayload>(token);
    const currentPasswordVersion = decodedToken.passwordVersion;

    // Use the configured API URL instead of hardcoded localhost
    const baseUrl = process.env.REACT_APP_API_URL || '';
    // Remove any trailing slashes from the API URL
    const socketUrl = baseUrl.replace(/\/$/, '');

    const socket: Socket = io(socketUrl, {
      auth: { token },
      transports: ['websocket', 'polling'],
      path: '/socket.io',
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 10000,
      autoConnect: true,
      withCredentials: true
    });

    socket.on('connect', () => {
      console.log('Socket connected successfully with ID:', socket.id);
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
    });

    socket.on('passwordVersionUpdated', (newPasswordVersion: string) => {
      if (currentPasswordVersion !== newPasswordVersion) {
        localStorage.clear();
        window.location.href = '/login';
      }
    });

    socket.on('forceLogout', (data) => {
      console.log('Force logout event received:', data); // Debug log
      localStorage.clear();
      window.location.href = '/login?message=Your session has been terminated';
    });

    socket.on('connect_error', (err) => {
      console.error('Socket connection error:', err);
    });

    // Debug: Log all incoming socket events
    socket.onAny((eventName, ...args) => {
      console.log('Received socket event:', eventName, args);
    });

    return () => {
      console.log('Cleaning up socket connection...'); // Debug log
      socket.disconnect();
    };
  }, []);
};

export default useAuthenticationSocket;