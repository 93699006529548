import { useEffect, useState } from "react";
import styles from "./documentCard.module.scss";
import { Button } from "components/UI/button/button";
import { Modal } from "components/modal/modal";
import { getSMBDocument, SMBDocument } from "actions/documentActions";
import { formatFilePath } from "./documentCard.utils";
import { formatImage } from "utils/imageFunctions";
import DownLoadArrow from "components/icons/downloadArrow";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RoutePaths } from "app/routing/routing";
import { LoadingContainer } from "components/UI/loadingContainer/loadingContainer";

interface IDocumentCard {
  thumbnail: any;
  title: string;
  date: string;
  number: number;
  filePath: string;
  id: string;
}
export const DocumentCard = ({
  thumbnail,
  title,
  date,
  number,
  filePath,
  id,
}: IDocumentCard) => {
  const { documentId } = useParams<{ documentId: string }>();
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [smbDocument, setSmbDocument] = useState<SMBDocument | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);


  const history = useHistory();
  const location = useLocation();

  const dateFormat: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  const showDocument = async () => {
    try {
      if (!documentId) history.push(`${location.pathname}/${id}`);
      setIsLoadingPreview(true);
      setShowDocumentModal(true);
      const newSmbDocument = await getSMBDocument(formatFilePath(filePath));
      setSmbDocument(newSmbDocument);
    } catch (error) {
      console.error('Failed to load document preview', error);
    } finally {
      setIsLoadingPreview(false);
    }
  };

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      const smbDocument = await getSMBDocument(formatFilePath(filePath));
      if (smbDocument && smbDocument.image) {
        let url;
        if (smbDocument.image.startsWith('blob:')) {
          url = smbDocument.image;
        } else {
          let blob;
          if (smbDocument.format.startsWith('text/') || smbDocument.format.includes('json')) {
            blob = new Blob([smbDocument.image], { type: smbDocument.format });
          } else {
            const byteString = atob(smbDocument.image.split(',')[1]);
            const mimeString = smbDocument.image.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            blob = new Blob([ia], { type: mimeString });
          }
          url = URL.createObjectURL(blob);
        }
  
        const link = document.createElement('a');
        link.href = url;
        link.download = title || 'document';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        if (!smbDocument.image.startsWith('blob:')) {
          URL.revokeObjectURL(url);
        }
      } else {
        console.error('SMB document or image not found');
      }
    } catch (error) {
      console.error('Download failed', error);
    } finally {
      setIsDownloading(false);
    }
  };
  

  const hideDocument = () => {
    setShowDocumentModal(false);
    history.push(RoutePaths.Documents);
  };

  useEffect(() => {
    if (documentId  === id) {
      showDocument();
    }
  }, [documentId]);


  const dateObject = new Date(date);
  const formattedDate = dateObject.toLocaleDateString("en-US", dateFormat);

  const image = formatImage(smbDocument?.format, smbDocument?.image);
  
  return (
    <div className={styles.card}>
      <div className={styles.thumbnailWrapper}>{thumbnail}</div>
      <p className={styles.title}>{title}</p>
      <div className={styles.cardTitleSection}>
        <p className={styles.date}>{formattedDate}</p>
        <p className={styles.date}>{number}</p>
      </div>
      
      <div className={styles.footer}>
        <Button
          id={"viewDocument"}
          onClick={showDocument}
          className={styles.viewDocument}
          mobileText="View"
        >
          View Document
        </Button>
        <Button
          id={"downloadDocument"}
          onClick={handleDownload}
          className={styles.viewDocument}
          mobileText={isDownloading ? "Downloading..." : "Download"}
          disabled={isDownloading}
        >
          <div className={styles.buttonTitle}>
            {isDownloading ? "Downloading..." : "Download"}
          </div>  
          <DownLoadArrow />
        </Button>
      </div>

      <Modal
        id="documentCard"
        title={title}
        show={showDocumentModal}
        onClose={hideDocument}
        className={styles.documentModal}
      >
        <div className={styles.modalContent}>
          {isLoadingPreview ? (
            <LoadingContainer loadingLabel="Loading document preview..." />
          ) : (
            image
          )}
        </div>
        <div className={styles.modalFooter}>
          <Button
            id={"downloadDocument"}
            onClick={handleDownload}
            className={styles.downloadButton}
            style="gradient"
            disabled={!smbDocument || isDownloading}
            mobileText={isDownloading ? "Downloading..." : "Download Document"}
          >
            {isDownloading ? "Downloading..." : "Download Document"}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
